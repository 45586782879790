@import "../src/imports/css/bootstrap.min.css";

@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';

.pt-4dot5 {
  padding-top: 2.5rem;
}

/*
* Critical task dialog
 */
.critical-dialog-container  {
  -webkit-animation: redBlink 1s infinite;
  -moz-animation: redBlink 1s infinite;
  animation: redBlink 1s infinite;
  box-shadow: 1px 1px 40px 10px rgba(0,0,0,0.75);
  -webkit-box-shadow: 1px 1px 40px 10px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 1px 40px 10px rgba(0,0,0,0.75);

  .mat-mdc-dialog-container {
    background: transparent;
    padding:10px;

    .alert-wrapper {
      background: #fff;
      padding:40px;

      h5.card-title span {
        border-bottom: 3px solid #34A0C2;
        margin-bottom: 1px;
      }
    }
  }
}

/*
* Warning CSS
 */
.errors {
  -webkit-animation: redBlink 1s infinite;
  -moz-animation: redBlink 1s infinite;
  animation: redBlink 1s infinite;
}

@-webkit-keyframes redBlink {
  0% {
    background-color: #c6345e;
  }
  49% {
    background-color: #c6345e;
  }
  50% {
    background-color: #3d3d3d;
  }
  99% {
    background-color: #3d3d3d;
  }
  100% {
    background-color: #c6345e;
  }
}

@-moz-keyframes redBlink {
  0% {
    background-color: #c6345e;
  }
  49% {
    background-color: #c6345e;
  }
  50% {
    background-color: #3d3d3d;
  }
  99% {
    background-color: #3d3d3d;
  }
  100% {
    background-color: #c6345e;
  }
}

@keyframes redBlink {
  0% {
    background-color: #c6345e;
  }
  49% {
    background-color: #c6345e;
  }
  50% {
    background-color: #3d3d3d;
  }
  99% {
    background-color: #3d3d3d;
  }
  100% {
    background-color: #c6345e;
  }
}
